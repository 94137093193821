<template>
  <v-app class="wrap">
    <SignAppBar />
    <v-container fluid class="fill-height" style="width: 100%;">
      <MainView class="fill-height" />
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'Sign',
  components: {
    SignAppBar: () => import('./core/SignAppBar'),
    MainView: () => import('./core/MainView'),
  },
  created() {

  },
  data: () => ({

  }),
}
</script>

<style lang="scss">
.partner_position {
  position: absolute;
}
.wrap {
  width: 100%;
  max-width: 395px;
  height: auto;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
}
</style>